.form {
  width: 100%;
  max-width: 426px;
  min-width: 330px;
  padding-bottom: 50px;
  margin: 0 auto;
}

.instructions {
  width: 100%;
  max-width: 430px;
  min-width: 330px;
  height: auto;
  color: #232323;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 70px;
}

.error-message {
  margin-left: 8px;
  font-size: 14px;
  color: #800;
}

#submit-btn {
  width: 100%;
  min-width: 330px;
}

.btn-container {
  width: 100%;
  padding-top: 15px;
}

.responsive-row {
  height: 0px;
}

/* Media queries for smaller screens */
@media (max-width: 576px) {
  .instructions {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 50px;
    min-width: 330px;
  }

  .required-fields {
    margin-top: 20px;
    font-size: 16px;
  }

  .form {
    padding-bottom: 30px;
    min-width: 330px;
  }

  .btn-container {
    justify-content: center;
  }

  #submit-btn {
    width: 100%;
    min-width: 330px;
  }

  .responsive-row {
    height: 0px;
  }
}

/* Media queries for larger screens */
@media (min-width: 577px) {
  .form {
    margin-right: 120px; /* Adjust this value to maintain spacing */
  }

  .btn-container {
    justify-content: flex-end;
  }

  #submit-btn {
    width: 60%;
  }

  .responsive-row {
    height: 0px;
  }
}

@media (min-width: 992px) {
  .btn-container {
    margin-bottom: 20px;
  }

  .responsive-row {
    height: 350px;
  }
}
