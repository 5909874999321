.trialHeader {
    min-height: 64px;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    background: white !important;
}

.trialHeader .headerContainer {
    max-width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.trialHeader .headerContainer .headerLogo {
    height: 64px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}

.trialHeader .headerContainer .headerLogo img {
    max-width: 140px;
    max-height: 30px;
    vertical-align: top;
}

.trialHeader .headerContainer .headerLeftOptions,
.trialHeader .headerContainer .headerRightOptions {
    display: flex;
    align-items: center;
}

.trialHeader .headerContainer .headerLeftOptions .headerLeftOption,
.trialHeader .headerContainer .headerRightOptions > li {
    list-style: none;
}

.trialHeader .headerContainer .headerLeftOptions .headerLeftOption a,
.trialHeader .headerContainer .headerRightOptions .headerBtn {
    padding: 0 20px;
    color: #505558;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 64px;
    font-size: .875rem;
    line-height: 1.25rem;
    letter-spacing: .00625rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif !important;
}

.trialHeader .headerContainer .headerLeftOptions .headerLeftOption a:hover,
.trialHeader .headerContainer .headerRightOptions .headerBtn:hover {
    margin-bottom: -2px;
    outline: none;
    color: #00D2B3;
    background-color: #f2f2f2;
    border-bottom: 2px solid #00D2B3;
}

.trialHeader .headerContainer .headerRightOptions .headerBtn {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    margin-right: 14px;
    display: block;
    height: 34px;
    width: 116px;
    text-decoration: none;
}

.trialHeader .headerContainer .headerRightOptions .headerBtn.freeTrialBtn {
    border: 2px solid #000;
}

.trialHeader .headerContainer .headerRightOptions .headerBtn.freeTrialBtn:hover {
    background: #000;
    color: #fff;
}

.trialHeader .headerContainer .headerRightOptions .headerBtn.buyNowBtn {
    color: white;
    background: #05CCAF;
    border: 2px solid #05CCAF;
}

.trialHeader .headerContainer .headerRightOptions .headerBtn.buyNowBtn:hover {
    background: #fff;
    color: #05CCAF;
}

.trialHeader .headerContainer .headerRightOptions .orLogin {
    font-size: 14px;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
}

.trialHeader .headerContainer .headerRightOptions .orLogin a {
    display: inline-block;
    margin-left: 5px;
    text-decoration: none;
    font-weight: 600;
    color: #4289c0;
}

.trialHeader .headerContainer .headerRightOptions .orLogin a:hover {
    outline: none;
}

.trialHeader .headerContainer .headerRightOptions .orLogin p {
    height: 10px;
}

/* Media queries for smaller screens */
@media (max-width: 992px) {
    .trialHeader .headerContainer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }

    .trialHeader .headerContainer .headerLeftOptions,
    .trialHeader .headerContainer .headerRightOptions {
        display: none;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        background-color: white;
        padding: 10px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
        position: absolute;
        top: 64px;
        left: 0;
        z-index: 1;
    }

    .trialHeader .headerContainer .headerLeftOptions.active,
    .trialHeader .headerContainer .headerRightOptions.active {
        display: flex;
    }

    .trialHeader .headerContainer .headerLeftOptions .headerLeftOption {
        margin-left: 0;
        margin-top: 10px;
        width: 100%;
    }

    .trialHeader .headerContainer .headerLeftOptions .headerLeftOption a {
        padding: 10px 0;
        width: 100%;
        text-align: left;
    }

    .trialHeader .headerContainer .headerRightOptions {
        flex-direction: column;
        align-items: center;
        margin-top: 310px;
    }

    .trialHeader .headerContainer .headerRightOptions .headerBtn {
        width: 100%;
        text-align: center;
        margin-right: 0;
        margin-top: 10px;
    }

    .trialHeader .headerContainer .headerRightOptions .orLogin {
        width: 100%;
        justify-content: center;
        margin-top: 10px;
    }

    .hamburgerMenu {
        display: block;
        cursor: pointer;
    }

    .hamburgerMenu div {
        width: 25px;
        height: 3px;
        background-color: #333;
        margin: 5px 0;
    }
}
