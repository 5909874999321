.footerContainer {
  line-height: 2.25rem;
  background-color: #f2f2f2;
  padding: 72px 0;
  margin-top: auto;
  z-index: 1;
  padding-bottom: 60px;
}

.footerContent a {
  text-decoration: none;
}

.footerContent a:hover {
  outline: none;
}

.footerContent .topFooter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footerContent .topFooter .footerAbout {
  width: 75%;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.footerContent .topFooter .footerAbout li {
  list-style: none;
}

.footerContent .topFooter .footerAbout .footerLogo {
  flex-basis: 50%;
}

.footerContent .topFooter .footerAbout .footerLogo img {
  width: 140px;
  height: 45px;
  display: block;
}

.footerContent .topFooter .footerAbout .clients,
.footerContent .topFooter .footerAbout .solutions,
.footerContent .topFooter .footerAbout .about {
  flex-basis: 33.3333%;
}

.footerContent .topFooter .footerAbout ul h3.mainRow {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.01875rem;
  margin-bottom: 0;
}

.footerContent ul li a {
  font-size: 0.75rem;
  color: #000 !important;
  font-weight: 400 !important;
  font-family: 'Open Sans', sans-serif !important;
}

.footerContent ul li a:hover {
  color: #0d9ddb !important;
  background: transparent;
}

.footerContent .topFooter .socialIcons {
  width: 25%;
}

.footerContent .topFooter .socialIcons ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footerContent .topFooter .socialIcons li {
  list-style: none;
  margin-left: 10px;
}

.footerContent .topFooter .socialIcons li a:hover {
  background: transparent;
}

.footerContent .topFooter .socialIcons li img {
  width: 22px;
  border-radius: 3px;
}

.footerContent .bottomFooter {
  width: auto;
  margin: 60px 12px 0;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 0.75rem;
  text-align: center;
  border-top: 1px solid #c5cacd;
}

.footerContent .bottomFooter .footerCopyright p {
  font-weight: 400;
  letter-spacing: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.footerContent .bottomFooter .footerLinks ul {
  display: flex;
}

.footerContent .bottomFooter .footerLinks li {
  list-style: none;
  margin-left: 10px;
}

.footerContent .bottomFooter .footerLinks li a {
  color: #000;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

.goToTopBtn {
  position: fixed;
  bottom: 1.25rem;
  right: 0;
  z-index: 100;
}

.goToTopBtn a {
  background: linear-gradient(0deg, #00d2b3, #00d2b3) !important;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 1.5rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff !important;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.1);
  font-size: 12px;
}

.goToTopBtn img {
  height: 24px !important;
}

/* Media queries for smaller screens */
@media (max-width: 930px) {
  .footerContent .bottomFooter {
    flex-direction: column;
    align-items: center;
  }

  .footerContent .bottomFooter .footerCopyright,
  .footerContent .bottomFooter .footerLinks {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footerContent .bottomFooter .footerCopyright {
    white-space: normal; /* Allow text to wrap */
  }

  .footerContent .bottomFooter .footerLinks ul {
    flex-direction: column;
    align-items: center;
  }

  .footerContent .bottomFooter .footerLinks li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .footerContent .topFooter {
    flex-direction: column;
    align-items: center;
  }

  .footerContent .topFooter .footerAbout {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .footerContent .topFooter .footerAbout .footerLogo,
  .footerContent .topFooter .footerAbout .clients,
  .footerContent .topFooter .footerAbout .solutions,
  .footerContent .topFooter .footerAbout .about,
  .footerContent .topFooter .socialIcons {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footerContent .topFooter .footerAbout .footerLogo img {
    margin: 0 auto;
  }

  .footerContent .topFooter .socialIcons ul {
    justify-content: center;
  }

  .footerContent .bottomFooter {
    flex-direction: column;
    align-items: center;
  }

  .footerContent .bottomFooter .footerCopyright,
  .footerContent .bottomFooter .footerLinks {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .footerContent .bottomFooter .footerCopyright {
    white-space: normal; /* Allow text to wrap */
  }

  .footerContent .bottomFooter .footerLinks ul {
    flex-direction: column;
    align-items: center;
  }

  .footerContent .bottomFooter .footerLinks li {
    margin-left: 0;
    margin-bottom: 10px;
  }
}
