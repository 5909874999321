@import '@web-standards/moonfish-styles/dist/themes/crescent.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');


html, body {
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none !important;
}

a {
  text-decoration: none !important;
}
