header, main, footer {
  flex-shrink: 0;
  // background-image: url('https://via.placeholder.com/350x150') !important;
  // background-size: cover !important;
  // background-position: cover !important;
}

body {
  --color-button-primary-background: #05CCAF !important;
  --color-button-primary-hover-background: #048268 !important;
  --color-button-primary-active-background: #05CCAF  !important;
}

.wrapper {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh !important;

  .content {
    flex-grow: 1;
    flex-shrink: 0;
  }

  .footer {
    flex-shrink: 0;
  }
}

.tabContainer .tabWrapper {
  justify-content: flex-start;
}

h1 {
  white-space: nowrap;
}
